// Stilos Custom aqui

.page-header-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.c-header.c-header-fixed {
  z-index: 2;
}

.migrate-data-button {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 1000;

  width: 140px;
  height: 40px;
  background: #321fdb;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  transition: all 0.3s;

  @media screen and (max-width: 768px) {
    top: 0px;
  }
}
